import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { EMPTY, NEVER, Observable, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { CommandService } from '../../../core/commands/command.service';
import { IResultCollector } from '../../../core/commands/resultcollector.interface';
import { DateTimeService } from '../../../core/date-time/date-time.service';
import { EventsService } from '../../../core/events/events.service';
import { EventData } from '../../../core/events/interfaces/event.class';
import {
  CoreFormConfirmModalIfDirtyCommand,
  CoreFormResetFormCommand,
  CoreFormScrollToTopCommand,
  CoreFormSetFormStatusCommand,
  CoreFormSetValueCommand,
  CoreFormSubmitFormCommand,
  CoreFormSuccessEventCommand,
  CoreModalConfirmMessageCommand,
  DtoFrontendModal,
  FormConfiguration,
  FormPluginRequest,
  FormState,
  FormStatusEnum,
  FormSubmitData,
  FormSubmitResult,
  FormSubmitStatusType,
  FormSubmitType,
  ICommand,
  IFormState,
  WebServiceResponse
} from '../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { FORM_EVENT_TYPE } from '../../../googleanalytics/googleanalytics.module';
import { IFormComponent } from '../../custom-component-factory/components/i-form-component';
import { DecoupledModalBridgeService } from '../../decoupled-modal/decoupled-modal-bridge.service';
import { ModalReference } from '../../decoupled-modal/models/decoupled-modal-bridge.interface';
import { DecoupledModalComponentInterface } from '../../decoupled-modal/models/decoupled-modal-component.interface';
import { BaseModalParamsInterface } from '../../decoupled-modal/models/modal-params.interface';
import { DestroyableObjectTrait } from '../../utils/destroyableobject.trait';
import {
  asIterableObject,
  backendTypeMatch,
  getInSafe,
  isNullOrUndefined,
  isNullOrWhitespace,
  jsonEqual,
  UtilsTypescript
} from '../../utils/typescript.utils';
import { EventFormSucceededInterface } from '../event-form-succeeded.interface';
import { FormLoadedEventData } from '../formloaded.eventdata';
import { IFormEvent } from '../interfaces/formevent.interface';
import { FormManagerService } from './form-manager.service';
import { FormComponent } from './form.component';
import { ChangedetectorReference } from '../../../core/changedetector/changedetectoreference';
import { DialogWrapperService } from '../../decoupled-modal/dialog-wrapper.service';
import { ValuechangedEventdata } from '../valuechanged.eventdata';
import { DynamicComponent } from '../../custom-component-factory/type-manager.decorator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Formstatecontainer } from '../formstatecontainer.class';
import { CanDeactivateComponentService } from '../../can-deactivate-component/can-deactivate-component.service';
import { CanDeactivateComponentInterface } from '../../can-deactivate-component/can-deactivate-component.interface';
import { finalizeWithReason } from '../../../utils/rxJsFinalizeWithReason';


/**
 * Form PopUp events.
 */
enum FormPopUpEvents {
  /**
   * Close the current window.
   */
  Close = 'close',
  CloseAndRebuild = 'closeAndRebuild',
  CloseAndReset = 'closeAndReset'
}

@DynamicComponent('Form')
@Component({
  selector: 'app-form-manager',
  templateUrl: './form-manager.component.html',
  providers: [FormManagerService, ChangedetectorReference]
})
export class FormManagerComponent
    extends DestroyableObjectTrait
    implements OnInit, OnChanges, OnDestroy, IFormComponent, DecoupledModalComponentInterface, CanDeactivateComponentInterface {

  @Input() formPlugin: string;

  @Input() params: any = {};

  @Input() subtitle: string = null;

  @Input() title: string = null;

  /**
   * Hide the component if the user does not have permissions to load the view
   */
  @Input() hideOn403: boolean = true;

  /**
   * Use autostart to initalize the form automatically in
   * ngInit()
   */
  @Input() autostart: boolean = false;

  @Output() canceled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() changedValue: EventEmitter<ValuechangedEventdata> = new EventEmitter<ValuechangedEventdata>();

  /**
   * Evento que se despacha después de cargar un formulario, esto incluye la carga inicial o cualquier
   * rebuild posterior.
   */
  @Output() formLoaded: EventEmitter<FormLoadedEventData> = new EventEmitter<FormLoadedEventData>();

  @Output() formEvent: EventEmitter<string> = new EventEmitter<string>(); // TODO: ACHSPRIME-817 Unificar formEventComplex y formEvent

  @Output() formEventComplex: EventEmitter<IFormEvent> = new EventEmitter<IFormEvent>();

  @Output() saveSucceed: EventEmitter<EventFormSucceededInterface> = new EventEmitter<EventFormSucceededInterface>();

  @ViewChild('container', {read: ViewContainerRef, static: true}) container;

  formState: Formstatecontainer;

  authError: boolean = false;

  refFormComponent: ComponentRef<FormComponent>;

  data: BaseModalParamsInterface;

  /**
   * Event that is emmited on modal closing / component destruction.
   *
   * This property should correspond to an object decorated by the
   * OutputDecorator class.
   */
  close: Subject<unknown> = new Subject<unknown>();

  /**
   * Controls iitial loading animation
   */
  public loadingAnimation?: boolean = null;

  /**
   * Reference to the form popup.
   */
  private refFormPopPup: ModalReference<FormManagerComponent>;

  /**
   * Boolean that indicates if the current component is a PopUp form.
   */
  private isPopUp = false;

  public loadErrorCode: number;

  /**
   * Creates a new instance of FormManagerComponent
   */
  constructor(
      private formManagerService: FormManagerService,
      private componentFactoryResolver: ComponentFactoryResolver,
      private dateTimeService: DateTimeService,
      private eventService: EventsService,
      private injector: Injector,
      private dmbs: DecoupledModalBridgeService,
      private router: Router,
      private canDeactivateComponentService: CanDeactivateComponentService,
      protected cdRef: ChangeDetectorRef,
      protected commandService: CommandService,
      protected cdReference: ChangedetectorReference,
      protected activatedRoute: ActivatedRoute,
      @Optional() protected dialogWrapperService: DialogWrapperService
  ) {

    super();
    canDeactivateComponentService.addComponent(this);
    // preparing for future submission
    formManagerService.submit
        // El debounce aqui es importante (podria ser un delay)
        // para asegurarnos de que el submit se hace despues de que se haya
        // estabilizado el formulario (por ejemplo, con cambios en la API de acciones)
        // ya que de lo contrairo el evento de cambio de valor en el campo no se ha propagado
        // todavia a los values del formulario
        .pipe(
            debounceTime(0),
            takeUntil(this.componentDestroyed$),
        )
        .subscribe(((value: { emitter: string, submitType: FormSubmitType }): void => {

          const formState: FormState = this.formManagerService.getFormState();

          // emitting submit event (necessary for GTM)
          this.eventService.emitEvent({
            action: FORM_EVENT_TYPE,
            params: {event: 'submit', formid: formState.FormId}
          } as EventData);

          const submitType: FormSubmitType = value.submitType ? value.submitType : FormSubmitType.Normal;
          this.doSubmitForm(this.formManagerService.form, value.emitter, formState, submitType);
        }).bind(this));

    this.registerCommands(commandService);
  }

  /**
   * Triggers an event to close the modal.
   *
   * @param {unknown} data
   */
  closeModal(data?: unknown): void {
    this.close.next(data);
    this.close.complete();
    this.close = null;
  }

  /**
   *
   * @param commandService
   */
  private registerCommands(commandService: CommandService): void {

    // Reset form command
    this.commandService.CommandObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((obj: any) => backendTypeMatch(CoreFormResetFormCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreFormResetFormCommand, () => Promise<boolean>>),
            filter(obj => this.formPlugin && !!this.formPlugin.match(obj.Argument.FormIdRegex))
        ).subscribe((next) => {
      next.AddResult(() => {
        this.resetForm();
        return Promise.resolve(true);
      });
    });

    // Scroll to top form command
    this.commandService.CommandObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((obj: any) => backendTypeMatch(CoreFormScrollToTopCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreFormScrollToTopCommand, () => Promise<boolean>>),
            filter(obj => this.formPlugin && !!this.formPlugin.match(obj.Argument.FormIdRegex))
        ).subscribe((next) => {
      next.AddResult(() => {
        this.formManagerService.scrollToTop.emit();
        return Promise.resolve(true);
      });
    });

    // Submit command
    this.commandService.CommandObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((obj: any) => backendTypeMatch(CoreFormSubmitFormCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreFormSubmitFormCommand, () => Promise<boolean>>),
            filter(obj => this.formPlugin && !!this.formPlugin.match(obj.Argument.FormIdRegex))
        ).subscribe((next) => {
      next.AddResult(() => {
        this.formManagerService.submitForm(next.Argument.Emitter, next.Argument.SubmitType ?? FormSubmitType.Normal);
        return Promise.resolve(true);
      });
    });

    // Comando de diálogo de confirmación solo si el formulario está sucio
    commandService.CommandObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((obj: any) => backendTypeMatch(CoreFormConfirmModalIfDirtyCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreFormConfirmModalIfDirtyCommand, () => Promise<boolean>>),
            filter(obj => this.formPlugin && !!this.formPlugin.match(obj.Argument.FormIdRegex))
        )
        .subscribe((next: IResultCollector<CoreFormConfirmModalIfDirtyCommand, (() => Promise<boolean>) | Observable<boolean>>) => {

          if (this.formManagerService.form.dirty !== true) {
            next.AddResult(() => Promise.resolve(true))
            return;
          }

          // Reboxeamos el comando al tipo que toca para la modal de confirmación, sino entraremos en bucle
          const command: CoreModalConfirmMessageCommand = Object.assign(new CoreModalConfirmMessageCommand(), next.Argument);
          Object.assign(command, {$type: CoreModalConfirmMessageCommand.$type});
          next.AddResult(() => this.commandService.executeCommandChain([command]));
        });

    // Form success event
    this.commandService.CommandObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((obj: any) => backendTypeMatch(CoreFormSuccessEventCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreFormSuccessEventCommand, () => Promise<boolean>>),
            filter(obj => this.formPlugin && !!this.formPlugin.match(obj.Argument.FormIdRegex))
        ).subscribe((next) => {
      next.AddResult(() => {
        const formState: IFormState = next.Argument.FormState;
        const reponseEventData: EventFormSucceededInterface = new EventFormSucceededInterface();
        reponseEventData.id = getInSafe(next, fs => fs.Argument.Result.id, '');
        reponseEventData.message = getInSafe(formState, fs => fs.ValidationErrors, []);
        reponseEventData.responseData = getInSafe(next, fs => ({
          Result: fs.Argument.Result,
          Status: FormSubmitStatusType.Success
        }), {}) as FormSubmitResult;
        // A veces pasa que en backend nos olvidamos de inicializar correctamente el comando, lo hacemos aquí.
        if (isNullOrUndefined(reponseEventData.responseData.Result) && formState && formState.SubmitResult) {
          reponseEventData.responseData = formState.SubmitResult;
        }
        reponseEventData.dirty = this.formManagerService.form.dirty;
        this.saveSucceed.emit(reponseEventData);
        return Promise.resolve(true);
      });
    });

    this.commandService.CommandObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((obj: any) => backendTypeMatch(CoreFormSetValueCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreFormSetValueCommand, () => Promise<boolean>>),
            filter(obj => this.formPlugin && !!this.formPlugin.match(obj.Argument.FormIdRegex))
        ).subscribe((next) => {
      next.AddResult(() => {
        if (!isNullOrUndefined(next.Argument.OverridedValues)) {
          Object.keys(next.Argument.OverridedValues).forEach(key => {
            const component: AbstractControl = this.formManagerService.getFormComponent(key);
            if (component) {
              this.setFormComponentValue(key, next.Argument.OverridedValues[key], true);
              this.cdRef.detectChanges();
            }
          })
        }
        return Promise.resolve(true);
      });
    });

    // Form success event
    this.commandService.CommandObservable
        .pipe(
            takeUntil(this.componentDestroyed$),
            filter((obj: any) => backendTypeMatch(CoreFormSetFormStatusCommand.$type, obj.Argument)),
            map((obj) => obj as IResultCollector<CoreFormSetFormStatusCommand, () => Promise<boolean>>),
            filter(obj => this.formPlugin && !!this.formPlugin.match(obj.Argument.FormIdRegex))
        ).subscribe((next) => {
      next.AddResult(() => {
        if (isNullOrUndefined(next.Argument?.Status) || next.Argument.Status.length === 0) {
          return Promise.resolve(true);
        }
        next.Argument.Status.forEach(status => {
          switch (status) {
            case FormStatusEnum.Dirty:
              this.formManagerService.form.markAsDirty();
              break;
            case FormStatusEnum.Pristine:
              this.formManagerService.form.markAsPristine();
              break;
            case FormStatusEnum.Untouched:
              this.formManagerService.form.markAsUntouched();
              break;
            case FormStatusEnum.Touched:
              this.formManagerService.form.markAsTouched();
              break;
          }
        });

        return Promise.resolve(true);
      });
    });
  }

  /**
   * Gets config when params or plugin is set or changes
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // If there are no changes, do nothing.
    if (!changes) {
      return;
    }

    const paramsChanged: boolean = !jsonEqual(getInSafe(changes, (x) => x.params.currentValue, null), getInSafe(changes, (x) => x.params.previousValue, null));
    const pluginChanged: boolean = !jsonEqual(getInSafe(changes, (x) => x.formPlugin.currentValue, null), getInSafe(changes, (x) => x.formPlugin.previousValue, null));
    const formStateChanged: boolean = getInSafe(changes, (x) => x.formState.currentValue, null) !== getInSafe(changes, (x) => x.formState.previousValue, null);

    if (paramsChanged === true || pluginChanged === true || formStateChanged === true) {
      this.resetForm();
    }
  }

  /**
   * Initializes FormManagerComponent resetting/preparing its values
   */
  ngOnInit(): void {
    if (this.autostart) {
      this.getConfig();
    }
  }

  /**
   * Retrieve form config from the server
   */
  getConfig(): void {
    if (!isNullOrUndefined(this.formPlugin)) {
      const formPlugin: string = this.formPlugin;
      const formParams: object = isNullOrUndefined(this.params) ? {} : UtilsTypescript.jsonClone(this.params);

      // Esto es un loop que manda cualquier cosa que haya en el query string al formulario,
      // es poco elegante porque contextualiza completamente el front con el back,
      // pero hay situaciones donde es más sencillo tener este loop automáticamente, sino tendríamos
      // que tocar dinámicamente el ArgumentMapping de frontend, lo que es muy muy complicado.
      const queryParams: Params = this.activatedRoute.snapshot.queryParams;
      if (queryParams) {
        for (const paramKey of Object.keys(queryParams)) {
          formParams['__query_' + paramKey.toLowerCase()] = queryParams[paramKey];
        }
      }

      if (this.loadingAnimation === null) {
        this.loadingAnimation = true;
      }

      const pluginRequest: FormPluginRequest = new FormPluginRequest();
      pluginRequest.FormId = formPlugin;
      pluginRequest.Arguments = formParams;

      this.formManagerService.init(pluginRequest)
          .pipe(
              take(1),
              takeUntil(this.componentDestroyed$),
              takeUntil(this.formManagerService.formOnInit),
              catchError((i) => {
                    if (i instanceof HttpErrorResponse) {
                      this.loadErrorCode = i.status;
                      switch (i.status) {
                        case 403:
                        case 401:
                          // Acceso denegado....
                          if (this.hideOn403 === true) {
                            const wsResponse403: WebServiceResponse = getInSafe((i), (x) => x.error, null);
                            console.warn(wsResponse403?.error?.message);
                            this.authError = true;
                            this.loadingAnimation = false;
                            this.cdRef.detectChanges();
                            return NEVER;
                          }
                          break;
                        case 0:
                        case 429:
                          // Too many connections
                          const wsResponse423: WebServiceResponse = getInSafe((i), (x) => x.error, null);
                          console.warn(wsResponse423?.error?.message);
                          this.authError = true;
                          this.loadingAnimation = false;
                          this.cdRef.detectChanges();
                          return NEVER;
                        case 404:
                          this.clearForm();
                          return NEVER;
                      }
                    }
                    return throwError(i);
                  }
              )
          )
          .subscribe(
              (response: Formstatecontainer) => {
                this.loadingAnimation = false;
                this.loadErrorCode = undefined;
                // Setting of formState for future Submission
                // In case of error 404 response is a boolean that is why we check this
                if (response instanceof Formstatecontainer) {
                  this.setForm(response);
                }
              }, (error) => {
                // We need to catch and do nothing here, because we already handle error in interceptors
              });
      return;
    }

    if (!isNullOrUndefined(this.formState)) {
      this.setForm(this.formState);
      return;
    }

    throw new Error('Invalid form config: ' + this.formPlugin);
  }

  /**
   * Completely reload the form, performs a new backend call
   * to retrieve the new configuration.
   */
  resetForm(): void {
    this.clearForm();
    this.getConfig();
  }

  /**
   * Clear all form contents
   */
  clearForm(): void {
    // Clear the UI componentes before clearing the form's internals! Some of the GUI componentes might access
    // form propierties during the destroy process or even trigger a change detection cycle
    this.container.clear();
    this.formManagerService.clearForm();
    this.loadingAnimation = null;
  }

  /**
   * Set (or reset) Form instance
   * @param {FormState} state
   */
  setForm(state: Formstatecontainer): void {
    // Nos aseguramos de que el viejo formulario ha sido destruido antes de cargar el nuevo
    if (!isNullOrUndefined(this.refFormComponent)) {
      // this.vcRef.clear();
      // this.refFormComponent.destroy();
    }
    this.doSetForm(state);
  }

  private doSetForm(container: Formstatecontainer): void {

    const state: FormState = container.formState;

    // copying original form state
    this.formManagerService.setFormState(container);

    // clear container to create form
    this.container.clear();

    // creating the form
    const formComponent: ComponentFactory<FormComponent> = this.componentFactoryResolver.resolveComponentFactory(FormComponent);

    this.refFormComponent = this.container.createComponent(formComponent, 0, null, this.injector);

    this.refFormComponent.instance.formPlugin = state.FormId;
    this.refFormComponent.instance.config = this.formManagerService.getComponentsConfig(state.Form, null);

    const title: string = isNullOrWhitespace(this.title) ? state.Form.Title : this.title;

    if (title && !this.dialogWrapperService) {
      this.refFormComponent.instance.title = title
    }

    if (title && this.dialogWrapperService) {
      this.dialogWrapperService.setTitle(title);
    }

    this.refFormComponent.instance.subtitle = isNullOrWhitespace(this.subtitle) ? state.Form.Description : this.subtitle;

    // subscribing to form events to propagate
    this.formManagerService.formEvent
        .pipe(
            takeUntil(this.componentDestroyed$),
            takeUntil(this.refFormComponent.instance.componentDestroyed$),
            takeUntil(this.formManagerService.formOnInit)
        )
        .subscribe((event) => this.formEvent.emit(event));

    this.formManagerService.formEventComplex
        .pipe(
            takeUntil(this.componentDestroyed$),
            takeUntil(this.refFormComponent.instance.componentDestroyed$),
            takeUntil(this.formManagerService.formOnInit)
        )
        .subscribe((event) => this.formEventComplex.emit(event));

    this.refFormComponent.instance.canceled
        .pipe(
            takeUntil(this.componentDestroyed$),
            takeUntil(this.refFormComponent.instance.componentDestroyed$),
            takeUntil(this.formManagerService.formOnInit)
        )
        .subscribe((value) => {
          this.canceled.emit(value);
        }, (error) => {
          console.error(error);
        });

    this.refFormComponent.instance.changedValue
        .pipe(
            takeUntil(this.componentDestroyed$),
            takeUntil(this.refFormComponent.instance.componentDestroyed$),
            takeUntil(this.formManagerService.formOnInit)
        )
        .subscribe((event) => {
          this.formManagerService.changedValue.emit(event);
          this.changedValue.emit(event);
        });

    this.refFormComponent.instance.afterInit
        .pipe(
            takeUntil(this.componentDestroyed$),
            takeUntil(this.refFormComponent.instance.componentDestroyed$),
            takeUntil(this.formManagerService.formOnInit)
        )
        .subscribe((event) => this.formEventComplex.emit({id: 'form-after-init', emiter: null, metadata: null}));

    // Avisar de que el formulario está cargado
    const formLoadedEventData: FormLoadedEventData = new FormLoadedEventData();
    formLoadedEventData.formState = state;
    formLoadedEventData.formManagerComponent = this;
    formLoadedEventData.formManagerService = this.formManagerService;
    this.formLoaded.emit(formLoadedEventData);

    this.eventService.emitEvent({
      action: FORM_EVENT_TYPE,
      params: {event: 'load', formid: state.FormId}
    } as EventData);

    this.cdRef.detectChanges();
  }

  @HostBinding('class')
  get hostWrapperClasses(): string {
    // Le ponemos un flex-row a esto, porque dentro el propio formulario es un flex-col
    // para poder ajustar anchuras máxima cuando la pantalla es muy grande
    return 'flex-row';
  }

  @HostBinding('attr.data-formplugin')
  get getDataFormPluginAttribute(): string {
    // Le ponemos un flex-row a esto, porque dentro el propio formulario es un flex-col
    // para poder ajustar anchuras máxima cuando la pantalla es muy grande
    return this.formPlugin ?? this.refFormComponent?.instance?.formPlugin ?? null;
  }

  /**
   *  Get form element value
   * @param {string} selector
   * @returns {any}
   */
  getFormComponentValue(selector: string): any {
    return this.formManagerService.getFormComponentValue(selector);
  }

  /**
   * Set a value for a form element.
   *
   * @param selector
   * @param value
   * @param propagate
   */
  setFormComponentValue(selector: string, value: any, propagate: boolean = false): void {
    this.formManagerService.setFormComponentValue(selector, value, propagate);
  }

  createFormSubmitDataFromViews(state: FormState): FormSubmitData {
    return this.createFormSubmitData(this.formManagerService.form, null, state, FormSubmitType.Normal);
  }

  protected createFormSubmitData(form: FormGroup, emitter: string, state: FormState, submitType: FormSubmitType): FormSubmitData {
    const submitData: FormSubmitData = new FormSubmitData();
    submitData.formInput = form.value;
    submitData.submitElement = (typeof emitter === 'string') ? emitter : state.Form.DefaultAction;
    submitData.SubmitType = submitType;
    return submitData;
  }

  /**
   * Submit form values to the server
   * @param form
   * @param {FormState} state
   * @param {bool} disableForm
   */
  protected doSubmitForm(form: FormGroup, emitter: string, state: FormState, submitType: FormSubmitType): void {

    const submitData: FormSubmitData = this.createFormSubmitData(form, emitter, state, submitType);

    // Evitar envíos solapados del formulario
    if (this.formManagerService.submittingRequest) {
      return;
    }

    this.formManagerService.submittingRequest = true;

    // En los submit de tipo rebuild o rebuild values, desactivamos el loader
    // para evitar confusión al usuario.
    const showSpinnerInmediately: boolean = [FormSubmitType.Rebuild, FormSubmitType.RebuildValues].includes(state.SubmitType) === true;

    this.formManagerService
        .postSubmit(submitData, showSpinnerInmediately)
        .pipe(
            takeUntil(this.componentDestroyed$),
            takeUntil(this.formManagerService.formOnInit),
            take(1),
            tap((i) => this.formManagerService.submitCount++),
            // Este finalize se llama cuando termina o cuando hay error, lo que hacemos
            // es permitir re-enviar haya o no habido error no controlado.
            finalizeWithReason(() => {
              this.formManagerService.submittingRequest = false;
              this.formManagerService.submitAttemptWithClientValidationErrors.next([]);
            }),
            catchError((error: any, obs: Observable<any>) => {
              // El tratamiento del error ya lo ha hecho el error-catcher-interceptor,
              // ponemos esto aquí solo para evitar la propagación en frontend de
              // la excepción
              return EMPTY;
            })
        )
        .subscribe(
            (result: Formstatecontainer) => {

              const formState: FormState = result.formState;

              // emitting submit-formState event (necessary for GTM)
              const resultStatus: string = !formState.SubmitResult ? FormSubmitStatusType.Success : formState.SubmitResult.Status;
              this.eventService.emitEvent({
                action: FORM_EVENT_TYPE,
                params: {event: 'result', formid: formState.FormId, result: resultStatus}
              } as EventData);

              // Aquí tenemos un gran "IF/ELSE" para distinguir entre el flujo obsoleto de ejecución de formulario
              // y el nuevo basado en el sistema de comandos, todavía no hay una distinción clara entre ambos
              // flujos ya que hay partes core de formularios (como los rebuilds) que se apalancan en los viejos
              // FormSubmitStatusType
              if (formState.SubmitResult.Actions && Object.keys(formState.SubmitResult.Actions).length) {
                const commands: {
                  [key: string]: ICommand
                } = UtilsTypescript.jsonClone(formState.SubmitResult.Actions);
                for (const commandKey of Object.keys(commands)) {
                  commands[commandKey]['FormState'] = formState;
                }
                this.commandService.executeCommandChain(asIterableObject(commands)).then();
              } else {
                // Se propone quitar el else ya que al emitir un commando no procesaba el Success y no cerraba el modal
                switch (formState.SubmitResult.Status) {
                  case FormSubmitStatusType.Success:
                    this.destroyPopUpForm(null, FormPopUpEvents.Close);
                    const response: EventFormSucceededInterface = {
                      id: getInSafe(formState, fs => fs.SubmitResult.Result.id, ''),
                      message: getInSafe(formState, fs => fs.ValidationErrors, []),
                      responseData: getInSafe(formState, fs =>
                          ({
                            Result: fs.SubmitResult.Result,
                            Status: fs.SubmitResult.Status
                          }), {}) as FormSubmitResult,
                      dirty: this.formManagerService?.form?.dirty ?? true,
                    };
                    // Establecemos el form state, pueden haber cambiado cosas como
                    // mensajes de validación
                    this.formManagerService.setFormState(result);
                    this.formManagerService.detectChangesAllForm();
                    this.saveSucceed.emit(response);
                    break;
                  case FormSubmitStatusType.Error:
                    this.formManagerService.setFormState(result);
                    this.formManagerService.detectChangesAllForm();
                    this.formManagerService.scrollToTop.next(null);
                    break;
                  case FormSubmitStatusType.Rebuild:
                    this.clearForm();
                    this.setForm(result);
                    this.cdRef.detectChanges();
                    break;
                  case FormSubmitStatusType.RebuildNewWindow:
                    this.createPopUpForm(result);
                    this.cdRef.detectChanges();
                    break;
                  case FormSubmitStatusType.RebuildCloseWindow:
                    this.destroyPopUpForm(result, FormPopUpEvents.CloseAndRebuild);
                    this.cdRef.detectChanges();
                    break;
                  case FormSubmitStatusType.RebuildValues:
                    this.formManagerService.setFormState(result);
                    this.formManagerService.patchValuesAndDefaultValuesFromFormState(formState, false);
                    this.formManagerService.detectChangesAllForm(false);
                    break;
                  case FormSubmitStatusType.Reset:
                    this.resetForm();
                    break;
                  case FormSubmitStatusType.ResetCloseWindow:
                    this.destroyPopUpForm(result, FormPopUpEvents.CloseAndReset);
                    this.cdRef.detectChanges();
                    break;
                  default:
                    throw new Error('El SubmitResult.Status del formulario no está dentro de los valores admitidos.');
                }
              }
              // Ponemos esto al final para evitar que durante un procesado de un submitresult,
              // se vuelva a procesar un submit.
              this.formManagerService.submittingRequest = false;
            });
  }

  /**
   * Sets the current form as PopUp form.
   */
  setAsPopup(): void {
    this.isPopUp = true;
  }

  /**
   * Creates a Pop Up window using a modal.
   *
   * @param {string} plugin Form identifier.
   * @param {object} params Form parameters.
   */
  createPopUpForm(state: Formstatecontainer): void {

    // If we are already in a modal, use it to render the new form.
    if (this.isPopUp) {
      this.clearForm();
      this.setForm(state);
      return;
    }


    let modalSettings: DtoFrontendModal = state.formState.RebuildNewWindowModalSettings;
    if (!modalSettings) {
      modalSettings = new DtoFrontendModal();
    }
    modalSettings.CssClasses = ['form-rebuild-new-window'];

    // Open a modal
    this.refFormPopPup = this.dmbs.showComponent(FormManagerComponent, modalSettings, {
      keyboard: false,
      focus: false,
      ignoreBackdropClick: true,
      class: 'form-rebuild-new-window'
    } as ModalOptions);

    this.refFormPopPup.instance$
        .pipe(
            takeUntil(this.componentDestroyed$)
        )
        .subscribe((modalForm) => {
          const content: FormManagerComponent = modalForm as FormManagerComponent;

          // Vincular los eventos de formulario
          content.saveSucceed
              .pipe(
                  takeUntil(content.componentDestroyed$),
                  takeUntil(this.componentDestroyed$),
                  takeUntil(this.formManagerService.formOnInit)
              )
              .subscribe((next) => this.saveSucceed.emit(next));

          content.changedValue
              .pipe(
                  takeUntil(content.componentDestroyed$),
                  takeUntil(this.componentDestroyed$),
                  takeUntil(this.formManagerService.formOnInit)
              )
              .subscribe((next) => this.changedValue.emit(next));

          // Inicializar
          content.clearForm();
          content.setForm(state);
          content.setAsPopup();

          content
              .saveSucceed
              .pipe(
                  takeUntil(content.componentDestroyed$),
                  takeUntil(this.componentDestroyed$),
                  takeUntil(this.formManagerService.formOnInit)
              )
              .subscribe(e => {
                this.saveSucceed.emit(e);
              });

          content
              .canceled
              .pipe(
                  takeUntil(content.componentDestroyed$),
                  takeUntil(this.componentDestroyed$),
                  takeUntil(this.formManagerService.formOnInit)
              )
              .subscribe(e => {
                this.canceled.emit(e);
              });

          content
              .changedValue
              .pipe(
                  takeUntil(content.componentDestroyed$),
                  takeUntil(this.componentDestroyed$),
                  takeUntil(this.formManagerService.formOnInit)
              )
              .subscribe(e => {
                this.changedValue.emit(e);
              });

          content
              .formEventComplex
              .asObservable()
              .pipe(
                  takeUntil(this.componentDestroyed$),
                  takeUntil(this.formManagerService.formOnInit),
                  filter((i) => i.emiter === FormPopUpEvents.Close || i.emiter === FormPopUpEvents.CloseAndRebuild || i.emiter === FormPopUpEvents.CloseAndReset),
                  // TODO: Add additional logic for unsubscriptions if other actions
                  // are added besides destroying the current pop up form.
                  take(1)
              )
              .subscribe((event: IFormEvent) => {

                this.refFormPopPup.doClose(null);
                this.refFormPopPup = null;
                this.formEventComplex.emit(event);
                this.clearForm();

                if (event.emiter === FormPopUpEvents.CloseAndRebuild) {
                  this.setForm(event.metadata as Formstatecontainer);
                } else if (event.emiter === FormPopUpEvents.CloseAndReset) {
                  this.getConfig();
                }
              });
        });
  }

  /**
   * Destroy the current PopUpForm and rebuild/reset/without rebuilding the parent form.
   *
   * Emits an event to destroy the current PopUpForm from the parent form.
   *
   * @param {FormState} state Rebuilded form state.
   */
  destroyPopUpForm(state: Formstatecontainer, formPopUpEvent: FormPopUpEvents): void {
    if (!this.isPopUp) {
      return;
    }

    this.formEventComplex.emit({
      id: null,
      emiter: formPopUpEvent,
      metadata: state
    } as IFormEvent)
  }

  initializeDynamicComponent(params: any): void {
    this.formState = params.formState;
    this.formPlugin = params.formPlugin;
    this.params = params.params;
    this.getConfig();
  }

  /**
   * OnDestroy
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.canDeactivateComponentService.removeComponent(this);
  }

  /**
   * Escucha del evento beforeunload del navegador para analizar si hay cambios
   * @param event
   */
  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnloadEventListener(event: BeforeUnloadEvent): void {
    if (!this.canLeaveForm()) {
      event.returnValue = 'PREVENT';
    }
  }

  /**
   * Comprueba si el componente se puede desactivar.
   * Actualmente mira si el formulario está dirty
   */
  canDeactivateComponent(): boolean {
    return this.canLeaveForm();
  }

  /**
   * Comprueba si se puede abandonar el formulario.
   * Comprueba que la configuración del formulario indica que
   * hay que verificar si el formulario está dirty y efectivamente lo está.
   */
  canLeaveForm(): boolean {
    const formConfiguration: FormConfiguration = this.formManagerService.getFormState()?.Form;

    if (!formConfiguration) {
      return true;
    }

    if (!formConfiguration.DoNotAllowLeavingFormIfDirty) {
      return true;
    }

    return !this.componentHasAnyStatus([FormStatusEnum.Dirty]);
  }

  /**
   * Comprueba si está en un estado inválido
   */
  componentHasAnyStatus(status: FormStatusEnum[]): boolean {

    let hasAnyState: boolean = false;
    status.forEach(x => {
      if (hasAnyState) {
        return;
      }
      switch (x) {
        case FormStatusEnum.Dirty:
          hasAnyState = this.formManagerService.form.dirty;
          break;
        case FormStatusEnum.Pristine:
          hasAnyState = this.formManagerService.form.pristine;
          break;
        case FormStatusEnum.Untouched:
          hasAnyState = this.formManagerService.form.untouched;
          break;
        case FormStatusEnum.Touched:
          hasAnyState = this.formManagerService.form.touched;
          break
      }

    })
    return hasAnyState;
  }
}
