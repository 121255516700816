/**
 * On Form Submit Succeeded event
 */
import { FormSubmitResult } from '../../core/models/ETG_SABENTISpro_Application_Core_models';

export class EventFormSucceededInterface {
  id: string
  message: any[]
  responseData: FormSubmitResult
  dirty: boolean;
}
