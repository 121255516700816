<div [id]="uniqueComponentId('check_radio-')" class="radio-check" *ngIf="value && config.showCheckIfNotEmpty">
  <i ngClass="fa fa-check"></i>
</div>
<div class="radio-options">
  <div *ngFor="let opt of config.options" class="radio-option">
    <input
        [attr.name]="config.name"
        type="radio"
        [checked]="opt.Key===value"
        (change)="value=$event.target.value"
        [value]="opt.Key"
        [id]="getOptionUniqueKey(opt)"
        [disabled]="isDisabled"
        #radioElement>
    <label [for]="getOptionUniqueKey(opt)" [id]="'label_' + getOptionUniqueKey(opt)" [title]="opt.Description ?? ''">
      {{ opt.Name }}
      <i
          class="o-btn-tooltip-help"
          *ngIf="opt.Description"
          [title]="opt.Description"
          placement="auto">?</i>
    </label>
  </div>
</div>
